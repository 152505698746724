import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import "../scss/components/productgrid.scss"

const ProductGrid = ({data}) => {
    return(
        <StaticQuery 
            query={ graphql`
            {
                allProductsJson {
                    edges {
                        node {
                            id
                            image
                            seo_title
                            seo_desc
                            title
                            slug
                            publish_date
                            available
                            price
                        }
                    }
                }
            }
        `}
            render={ data => (
                <ul className={ `product-grid` }>

                    { data.allProductsJson.edges.sort((a, b) => new Date(a.node.publish_date) - new Date(b.node.publish_date)).map( (product) => 
                        <li key={ product.node.slug } className={ `grid-item width-${ data.allProductsJson.edges.length >= 3 ? 3 : data.allProductsJson.edges.length }` } >
                            <Link to={ `/${product.node.slug}/` }>
                                <figure>
                                    <img src={ product.node.image} alt={ product.node.title } width="600" height="400" />
                                </figure>
                            </Link>
                            <div className={ `title-description` }>
                                <h2><Link to={ `/${product.node.slug}/` }>{ product.node.title }</Link></h2>
                                <div className={ `price-availability` }>
                                    { product.node.available === true
                                        ? <span className={ `availability available` }>Currently Available</span>
                                        : <span className={ `availability not-available` }>Not Available</span>
                                    }
                                    {
                                        product.node.price !== 'Private Collection'
                                            ? <span className={ `price` }>${ product.node.price }</span>
                                            : <span className={ `price` }>{ product.node.price }</span>   
                                    }
                                </div>
                                <p className={ `description` }>
                                        { product.node.seo_desc }
                                </p>
                            </div>
                            <div className={ `link` }>
                                <Link className="button" to={ `/${product.node.slug}/` }>Details</Link>
                            </div>
                        </li>
                    ) }
                </ul>
            )}
        />
    )
}

export default ProductGrid
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/store.scss"
import ProductGrid from "../components/productgrid"

const StorePage = () => (
    <Layout>
        <Seo
        title="Store"
        description="Valley Gem Properties has various items for sale from tractors to trinkets."/>

        <section 
        id={ `store_product_content` }
        className={ `content-section bg-color-navy` }>
            <div 
            className={ `container-xxl inner` }>
                <h1>Store</h1>
                <ProductGrid />
            </div>
        </section>
    </Layout>
)

export default StorePage

